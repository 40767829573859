import React, { useEffect, useState } from "react";
import * as ReactDOMClient from "react-dom/client";
import singleSpaReact from "single-spa-react";
import { util } from "@deloitte-global-cloud-services/dsoe-portal-core";
import { context } from "../auth";

const AuthenticatedViewComponent = (props: any) => {

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);

    useEffect(() => {
        const sub = context.subscribe(({ isAuthenticated, pending }) => {
            setIsAuthenticated(isAuthenticated);
            setPending(pending);
        });
        return () => {
            sub.unsubscribe();
        };
    });

    if(isAuthenticated) return props.children;
    else if(isAuthenticated === undefined || pending) return (
        <div className='text-center'>
            <img src="/assets/images/spin-1s@200px.gif" height={60} width={60}/>
        </div>
    );
    else if(isAuthenticated === false) return (<>Failed to authenticate user</>);
}

const lifecycles = singleSpaReact({
    React,
    ReactDOMClient,
    rootComponent: AuthenticatedViewComponent,
    errorBoundary: util.SingleSpaUtil.handleError
});

export const AuthenticatedView = { name: 'auth-view', ...lifecycles };
