import { PublicClientApplication, SilentRequest, AuthenticationResult, Configuration, LogLevel, AccountInfo, InteractionRequiredAuthError, RedirectRequest, PopupRequest, EventType, EndSessionRequest } from "@azure/msal-browser";
import { context } from './dsoe-portal-auth';

/**
 * AuthModule for application - handles authentication in app.
 */
export class AuthModule {

    private myMSALObj: PublicClientApplication; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/classes/_src_app_publicclientapplication_.publicclientapplication.html
    private account: AccountInfo | null; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-common/modules/_src_account_accountinfo_.html
    private loginRedirectRequest: RedirectRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_redirectrequest_.html
    private loginRequest: PopupRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_popuprequest_.html
    private profileRedirectRequest: RedirectRequest;
    private profileRequest: PopupRequest;
    private silentProfileRequest: SilentRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_silentrequest_.html

    constructor(clientId: string, tenantId: string, scope: string) {
        /**
         * Configuration class for @azure/msal-browser: 
         * https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_config_configuration_.html
         */
        const MSAL_CONFIG: Configuration = {
            auth: {
                clientId,
                authority: `https://login.microsoftonline.com/${tenantId}`,
                navigateToLoginRequestUrl: false
            },
            cache: {
                cacheLocation: "sessionStorage", // This configures where your cache will be stored
                storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level: any, message: any, containsPii: any) => {
                        if (containsPii) {	
                            return;	
                        }	
                        switch (level) {	
                            case LogLevel.Error:	
                                console.error(message);	
                                return;	
                            case LogLevel.Info:	
                                console.info(message);	
                                return;	
                            case LogLevel.Verbose:	
                                console.debug(message);	
                                return;	
                            case LogLevel.Warning:	
                                console.warn(message);	
                                return;	
                        }
                    }
                }
            }
        };
        this.myMSALObj = new PublicClientApplication(MSAL_CONFIG);
        this.account = null;

        this.loginRequest = {
            scopes: []
        };

        this.loginRedirectRequest = {
            ...this.loginRequest,
            redirectStartPage: `${window.location.origin}`
        };

        this.profileRequest = {
            scopes: [scope]
        };

        this.profileRedirectRequest = {
            ...this.profileRequest,
            redirectStartPage: `${window.location.origin}`
        };

        this.silentProfileRequest = {
            scopes: [scope],
            forceRefresh: false
        };
    }

    /**
     * Calls getAllAccounts and determines the correct account to sign into, currently defaults to first account found in cache.
     * TODO: Add account chooser code
     * 
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
     private getAccount(): AccountInfo | null {
        // need to call getAccount here?
        const currentAccounts = this.myMSALObj.getAllAccounts();
        if (currentAccounts === null) {
            console.log("No accounts detected");
            return null;
        }

        if (currentAccounts.length > 1) {
            // Add choose account code here
            console.log("Multiple accounts detected, need to add choose account code.");
            return currentAccounts[0];
        } else if (currentAccounts.length === 1) {
            return currentAccounts[0];
        }

        return null;
    }

    /**
     * Checks whether we are in the middle of a redirect and handles state accordingly. Only required for redirect flows.
     * 
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirect-apis
     */
    loadAuthModule(): void {
        const accounts = this.myMSALObj.getAllAccounts();
        if (accounts.length > 0) {
            this.myMSALObj.setActiveAccount(accounts[0]);
        }

        // console.log('get active account', this.myMSALObj.getActiveAccount());

        // handle auth redired/do all initial setup for msal
        this.myMSALObj.handleRedirectPromise().then(authResult=> {
            this.handleResponse(authResult)
        }).catch(err=>{
            // TODO: Handle errors
            console.log(err);
        });
    }

    /**
     * Handles the response from a popup or redirect. If response is null, will check if we have any accounts and attempt to sign in.
     * @param response 
     */
    async handleResponse(response: AuthenticationResult | null) {
        if (response !== null) {
            this.account = response.account;
        } else {
            this.account = this.getAccount();
        }

        if (this.account) {
            const token = await this.getProfileTokenRedirect();
            const graphAPIToken = await this.getUserAPITokenRedirect();
            const shortName = this.account?.name?.split(",").reverse().map(name => name.trim().charAt(0)).join("")
            context.next({
                graphAPIToken,
                sessionToken: token,
                error: false,
                isAuthenticated: true,
                pending: false,
                user: {
                    name: this.account?.name,
                    email: this.account?.username,
                    shortName
                }
            });
            const mainContent: any = document.getElementById("MAIN_CONTENT");
            const loaderContent: any = document.getElementById("MAIN_LOADER");
            if(mainContent) {
                mainContent.style.display = "";
                loaderContent.style.display = "none";
            }
        } else {
            this.myMSALObj.loginRedirect(this.loginRedirectRequest);
        }
    }

    /**
     * Gets the token to read user profile data from MS Graph silently, or falls back to interactive redirect.
     */
    async getUserAPITokenRedirect(): Promise<string|null> {
        if (this.account) {
            this.silentProfileRequest.account = this.account;
        }
        return this.getTokenRedirect({...this.silentProfileRequest, scopes: ["User.ReadBasic.All"]}, this.profileRedirectRequest);
    }

    /**
     * Gets the token to read user profile data from MS Graph silently, or falls back to interactive redirect.
     */
    async getProfileTokenRedirect(): Promise<string|null> {
        if (this.account) {
            this.silentProfileRequest.account = this.account;
        }
        return this.getTokenRedirect(this.silentProfileRequest, this.profileRedirectRequest);
    }

    /**
     * Gets a token silently, or falls back to interactive redirect.
     */
    private async getTokenRedirect(silentRequest: SilentRequest, interactiveRequest: RedirectRequest): Promise<string|null> {
        try {
            const response = await this.myMSALObj.acquireTokenSilent(silentRequest);
            return response.accessToken;
        } catch (e) {
            console.log("silent token acquisition fails.");
            if (e instanceof InteractionRequiredAuthError) {
                console.log("acquiring token using redirect");
                this.myMSALObj.acquireTokenRedirect(interactiveRequest).catch(console.error);
            } else {
                console.error(e);
                const win: Window = window;
                win.location = window.location.origin;
            }
        }

        return null;
    }

    /**
     * Logs out of current account.
     */
     logout(): void {
        let account: AccountInfo | undefined;
        if (this.account) {
            account = this.account
        }
        const logOutRequest: EndSessionRequest = {
            account,
            postLogoutRedirectUri: window.location.origin
        };
        
        this.myMSALObj.logoutRedirect(logOutRequest);
    }
}
