import { BehaviorSubject } from "rxjs";
import { AuthModule } from "./AuthModule";
import { UserSession } from "./types";
  
export const context = new BehaviorSubject<UserSession>({});

export const authModule = new AuthModule(process.env.CLIENT_ID, process.env.TENANT_ID, process.env.DEFAULT_SCOPE);

// Load auth module when browser window loads. Only required for redirect flows.
window.addEventListener("load", () => {
  context.next({
    pending: true
  });
  authModule.loadAuthModule();
});